import React from 'react';
import img2 from '../assets/churchmap.png';


const Section2 = () => {
  return (
    <section id="Contact">

    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='rounded-md w-[550px] mx-auto my-4' src={img2} alt='/' />
        <div className='flex flex-col justify-center'>
          <p className='text-[#D95323] font-bold text-3xl '>Location</p>
          <h1 className='md:text-xl sm:text-l text-l font-bold py-2'>1420 Victoria st N, Kitchener ON, Canada N2B 3E2</h1>
          <p className='text-[#D95323] font-bold text-3xl '>Service Times</p>

          <h1 className='md:text-xl sm:text-l text-l font-bold py-2'>Saturday 6:30 PM</h1>
          <h1 className='md:text-xl sm:text-l text-l font-bold py-2'>Sunday 11:00 AM</h1>

          <p className='text-[#D95323] font-bold text-3xl '>Contact</p>

          <h1 className='md:text-xl sm:text-l text-l font-bold py-2'>By email : jose@thelittleflock.com</h1>
          <h1 className='md:text-xl sm:text-l text-l font-bold py-2'>By Phone : 519-580-9576</h1>


          <p>

          </p>
        </div>
      </div>
    </div>
    </section>

  );
};

export default Section2;